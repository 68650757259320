import React from "react"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "./screens/treatment-in-minutes/hero"
import ThanksComponent from "./screens/treatment-in-minutes/thank-you"
import BodyLanding from "../components/body-landing"

const ThankYou = ({ location }) => {
	const firstName = (location.state) ? location.state.first_name : ""
	return (
		<main>
			<Header />
			<Hero />
			<ThanksComponent first_name={firstName} />
			<BodyLanding landingPage="treatment-in-minutes" />
		</main>
	)
}

export default ThankYou

export const Head = () => (
	<Seo
	  title="Precise treatment in minutes"
	  description="On-site PCR replaces send-outs to improve turnaround time and revenue."
	/>
  )
